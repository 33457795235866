input.rangecs {
  -webkit-appearance: none;
  -moz-apperance: none;
  width: 200px;
  height: 8px;
  padding: 0;
  border-radius: 2px;
  border: 1px solid #6c757d;
  outline: none;
}
