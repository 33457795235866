body,
html,
#root {
  height: 100%;
}

#navbar {
  height: 60px;
}

#sidebar {
  min-height: calc(100vh - 60px);;
}

body {
  padding-top: 60px;
}

.sticky-offset {
  top: 60px;
  min-height: calc(100vh - 60px);
}

.sticky-bottom-box { 
  height: 80px;
  padding-left: 15px;
  margin-top: auto;
}

section {
  margin-top: 30px;
}

/* https://stackoverflow.com/a/21261303 */
dl.compact dt {
  min-width: 50px;
  display: inline-block;
}
dl.compact dd {
  display: inline;
}
dl.compact dd::before {
  content: '';
}
dl.compact dd::after {
  display: block;
  content: '';
}

.icon-spin {
  -webkit-animation: icon-spin 2s infinite linear;
          animation: icon-spin 2s infinite linear;
}

@-webkit-keyframes icon-spin {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(359deg);
            transform: rotate(359deg);
  }
}

@keyframes icon-spin {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(359deg);
            transform: rotate(359deg);
  }
}

input[readonly="readonly"] {
  border:0px;
}

.react-bootstrap-table table {
  table-layout: auto !important;
}

.figtitle {
  width: 376px;
  text-align: center;
  margin-top: 1em;
}
