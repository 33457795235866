.bodytext {
  max-width: 45em;
}

.bodytext * {
  scroll-margin-top: 60px;
}

/* corresponds to h3 */
.bodytext .title {
  font-size: 1.75rem;
  margin-bottom: .5rem;
  font-weight: 500;
  line-height: 1.2;
}
